body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--darkBlue);
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --themeColor: #44f6f0;
  --white: #fff;
  --purple: #8468de;
  --footer: #0d0a18;
  --circle: #ddc3f3;
  --darkBlue: #110b27;
  --deepPurple: #41045f;
  --buttonColor: #07fff7;
  --bgColor: #061143;
  --testimonialbg: #09061A;
}

button{
  background: none;
  color: white;
  border: 2px solid var(--themeColor);
  padding: .5rem;
  font-weight: bold;
  border-radius: 5rem;
  font-weight: bold;
}

button:hover{
  background-color: var(--buttonColor);
  cursor: pointer;
  color: var(--white);
}

h1{
  font-size: clamp(3rem, 1rem + 10vh, 4rem);
  color: var(--white);
}
