.newsletter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: var(--darkBlue);
    z-index: 1;
}

.news-heading{
    flex-direction: column;
    margin: 15rem 17rem;
    position: absolute;
}

.news-heading h1{
    color: var(--white);
    font-size: 3rem;
    text-transform: uppercase;
}

.news-divider{
    border: 1px solid var(--themeColor);
    width: 47.5%;
    margin-left: 21.5rem;
}

.news-circle{
    position: absolute;
    width: 600px;
    height: 600px;
    background: linear-gradient(#33ccff, #41045f);
    border-radius: 50%;
    right: -11rem;
    animation: animate 8s linear infinite;
    margin-top: -3rem;
    z-index: 9999;
    margin-top: -12rem;
}

@keyframes animate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.news-circle:after{
    content: '';
    background-color: var(--darkBlue);
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border-radius: 50%;
}

.news-circle span{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#33ccff, #41045f);
    border-radius: 50%;
}

.news-circle>:nth-child(1){
    filter: blur(5px);
}

.news-circle>:nth-child(2){
    filter: blur(10px);
}

.news-circle>:nth-child(3){
    filter: blur(20px);
}

.news-circle>:nth-child(41){
    filter: blur(55px);
}

.email-box{
    margin-top: 10rem;
    z-index: 9999;
    position: absolute;
}

.email-box input{
    position: absolute;
    width: 40rem;
    height: 5rem;
    background: none;
    border: 2px solid var(--white);
    border-radius: 20rem;
    padding: 0 2rem;
    font-size: 1rem;
    cursor: text;
}

.email-box button{
    margin-left: 30rem;
    height: 5rem;
    width: 10rem;
    cursor: pointer;
    font-size: 1.3rem;
    position: absolute;
}

@media screen and (max-width: 680px) {

    .newsletter{
        margin-top: 4rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 1rem;
    }

    .news-heading{
        display: flex;
        width: 100%;
    }

    .news-heading h1{
        font-size: 1rem;
        width: 100%;
    }

    .news-divider{
        border: 1px solid var(--themeColor);
        margin-left: 7rem;
        width: 6.5rem;
    }

    .news-circle{
        width: 20rem;
        height: 20rem;
        z-index: 9999;
        position: relative;
        margin-top: -27rem;
        margin-right: 40rem;
    }

    .email-box input{
        width: 17rem;
        height: 2rem;
        font-size: .6rem;
    }

    .email-box button{
        position: absolute;
        z-index: 9999;
        margin-left: 12rem;
        height: 2rem;
        width: 5rem;
        font-size: 0.6rem;
    }
}

@media screen and (max-width: 1440px) {
    .newsletter{
        margin-top: -1rem;
    }

    .email-box input{
        width: 30rem;
        height: 4rem;
    }

    .email-box button{
        height: 4rem;
        margin-left: 22rem;
        width: 8rem;
        font-size: .9rem;
    }

    .news-circle{
        margin-right: -5rem;
    }
}